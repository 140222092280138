import buyAtAmazon from "./amazon.png";
import buyAtAudible from "./audible.png";
import "./index.css";

export default function() {
    return (
        <>
            <div className="buyat" style={{color: "white", width: "400px", maxWidth: "95%", margin: "0 auto", fontSize: "1.25em"}}>
                    <h1 className="fiction">Buy the Books</h1>
        <h3><a style={{color: "yellow"}} href="/list">Join our Email List for a FREE&nbsp;BOOKMARK</a></h3>
                    <a style={{color: "white"}} href="https://www.amazon.com/Rich-Hosek/e/B08KG71LMV">
                    <p>Print and eBook available at Amazon</p>
                <img src={buyAtAmazon} alt="Available at Amazon" />
                </a>
                <a style={{color: "white"}} href="https://www.audible.com/author/Rich-Hosek/B08KG71LMV">
                    <p>Audiobook avaialble at Audible</p>
                    <img src={buyAtAudible} alt="" />
                </a>
            </div>
        </>
    )
}