import { useContext, useState } from "react";
import FeedContext from "../../FeedContext";
import "./index.css";
import TruncateMarkup from "react-truncate-markup";
import Truncate from '@cityads/react-truncate';
import TextFit from "react-textfit";

export default function Novels() {

    const feed = useContext(FeedContext);
    const books = feed.items.filter(item => item.season < 99 && item.episode == 1);
    const [season, setSeason] = useState(books[0]?.season || 1);
    console.log("BOOKS", books);
    const loadEpisodePlayer = (url) => {
        // eslint-disable-next-line no-restricted-globals
        location.assign(url);
    }

    return (
        <>
            <h1 className="fiction">Audiobook Novels</h1>
            {books.map(book => <img className="bookCover" onClick={() => setSeason(book.season)} src={`bookcovers/${book.season}.jpg`} />)}
            <div id="novelsContainer">
                <div id="stories">
                    {feed.items.filter(item => item.season == season).reverse().map(story => (
                        <a key={`${story.season}:${story.episode}` } className="story" onClick={() => loadEpisodePlayer(`/episode/${story.season}/${story.episode}`)}>
                            <img src={story?.imageLink} alt={story?.title} />
                            <div className="storyInfo">
                                <h3><TextFit mode="single" max={16}>{story?.title}</TextFit></h3>
                                <div className="showNotes"><div>{story?.contentPlain}</div></div>
                            </div>

                        </a>
                  
                
                    ))}
                </div>
            </div>
        </>
    )
}