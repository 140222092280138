import "./index.css"
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import states from "./states.json";
import base64 from "base-64";
import utf8 from "utf8";
import bookmark from "./Bookmark.Front.sideways.png";

export default function Bookmark() {
    let history = useHistory();
    const [formValues, setFormValues] = useState({
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
    });
    const {encodedId} = useParams();
    const handleSubmit = (event) => {
        let bytes = base64.decode(encodedId);
        let text = utf8.decode(bytes);
        let id = text.split(":")[1] / 97;
        event.preventDefault();
        axios.post("/api/list/bookmark", { ...formValues, id })
            .then(response => {
                console.log(response.data);
                history.push("/")
            })
            .catch(error => console.error(error));
    }

    const handleChange = ({target}) => {
        setFormValues({...formValues, [target.name]: target.value})
        
    }


    return (
        <>
            <h1 className="fiction">Free Bookmark</h1>
            <img className="bookmark" src={bookmark} alt="Sidways view of bookmark" />
            <p className="whiteText">To claim your free book mark, enter your address below. <em>(Sorry, we can only do US addresses for the time being.)</em></p>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="address">Address</label>
                    <input
                        type="text"
                        name="address"
                        id="address"
                        value={formValues.address}
                        onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="address2">Address 2</label>
                    <input
                        type="text"
                        name="address2"
                        id="address2"
                        value={formValues.address2}
                        onChange={handleChange} />
                </div>
                
                <div>
                    <label htmlFor="address2city">City</label>
                    <input
                        type="text"
                        name="city"
                        id="city"
                        value={formValues.city}
                        onChange={handleChange} />
                </div>
                <div className="computerOnly">
                    <label htmlFor="state">State</label>
                    <select 
                        className="small"
                        type="text"
                        name="state"
                        id="state"
                        value={formValues.state}
                        onChange={handleChange}>
                            {Object.entries(states).map(([key, value]) => <option key={key} value={key}>{value}</option>)}
                    </select>
                    <label htmlFor="zip">Zip</label>
                    <input
                        className="small"
                        type="text"
                        name="zip"
                        id="zip"
                        value={formValues.zip}
                        onChange={handleChange} />
                </div>
                
                <div className="phoneOnly">
                    <label htmlFor="state">State</label>
                    <select 
                        className="small"
                        type="text"
                        name="state"
                        id="state"
                        value={formValues.state}
                        onChange={handleChange}>
                            {Object.entries(states).map(([key, value]) => <option key={key} value={key}>{value}</option>)}
                    </select>
                </div>
                <div className="phoneOnly">
                    <label htmlFor="zip">Zip</label>
                    <input
                        className="small"
                        type="text"
                        name="zip"
                        id="zip"
                        value={formValues.zip}
                        onChange={handleChange} />
                </div>
                
                
                <button className="submit">Send Me My Bookmark!</button>
            </form>
        </>


    )
}