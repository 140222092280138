import { useContext, useState } from "react";
import {Link, useParams, useHistory, Redirect } from "react-router-dom";
import FeedContext from "../../FeedContext";
import "./index.css";
import TruncateMarkup from "react-truncate-markup";
import Truncate from '@re-dev/react-truncate';
import TextFit from "react-textfit";

export default function Stories() {

    const feed = useContext(FeedContext);
    const loadEpisodePlayer = (url) => {
        // eslint-disable-next-line no-restricted-globals
        location.assign(url);
    }
    const genres = [
        {name: "All", value: "all"},
        {name: "Detective", value: "det"},
        {name: "Fantasy/Urban Fantasy", value: "fantasy"},
        {name: "Ghost Stories", value: "ghost"},
        {name: "Glitches in the Smulation", value: "sim"},
        {name: "Horror", value: "horror"},
        {name: "Funny", value: "humor"},
        {name: "Love Stories", value: "love"},
        {name: "Monsters", value: "mon"},
        {name: "Mystery", value: "mystery"},
        {name: "Paranormal", value: "para"},
        {name: "Science Fiction", value: "sf"},
        {name: "Speculative Fiction", value: "spec"}
    ]
    const {genre} = useParams();
    const history = useHistory();

    const [genreValue, setGenreValue] = useState(genres.find(g => g.value === genre?.toLowerCase())?.value ?? "all");

    if (genre && genre != genreValue) {
        return <Redirect to={`/stories/${genreValue}`} />
    }

    return (
        <>
            <h1 className="fiction" style={{width: "100%"}}>Stories <select style={{maxWidth: "11rem"}} value={genreValue} onChange={(event) => {
                    history.push(`/stories/${genreValue}`);
                    setGenreValue(event.target.value);
                }}>
                    {genres.map(genre => <option key={genre.value} value={genre.value}>{genre.name}</option>)}
                </select></h1>
     
            <div id="storiesContainer">
                <div id="stories">
                    {feed.items.filter(item => item.season >= 99).filter(item => {
                        if (genreValue === "all") {
                            return true;
                        }
                        return item.tags?.includes(genreValue);
                    }).map(story => (
                        <a key={`${story.season}:${story.episode}` } className="story" onClick={() => loadEpisodePlayer(`/episode/${story.season}/${story.episode}`)}>
                            <img src={story?.imageLink} alt={story?.title} />
                            <div className="storyInfo">
                                <h3><TextFit mode="single" max={16}>{story?.title}</TextFit></h3>
                                <div className="showNotes">{story?.contentPlain}</div>
                            </div>

                        </a>
                    ))}
                    {genreValue !== "all" ? 
                    <h1 style={{cursor: "pointer"}} onClick={event => {
                        event.preventDefault();
                        history.push(`/stories/all`);
                        setGenreValue("all");
                    }}>View ALL Stories</h1> : <></> }

                </div>
            </div>
        </>
    )
}