import { useContext } from "react";
import FeedContext from "../../FeedContext";
import "./index.css"

function Home() {


    const feed = useContext(FeedContext);
    const latestStory = feed?.items?.find(item => item.season >= 99);
    console.log(latestStory)

    const loadEpisodePlayer = () => {
        // eslint-disable-next-line no-restricted-globals
        location.assign(`/episode/${latestStory?.season}/${latestStory?.episode}`);
    }

    return (
        <>
            <h1 className='fiction'>FICTION PODCAST</h1>
            <div id="homeContainer">
                <p className="subscribe" style={{marginTop: 0}}>Fresh, new, original stories to <em>NOT</em> fall asleep by<br />Subscribe, Rate, Review and Share.</p>
                {/* <p id="podcastawards" className="subscribe" style={{marginBottom: "1.5em"}}><a style={{textDecoration: "underline", color: "white"}} href="https://www.podcastawards.com/"><img src="https://www.podcastawards.com/images/pa_logo.png" /><br /><div>Vote for Bedtime Stories</div></a></p> */}
                
                <h1 className="emailList"><a style={{ color: "yellow" }} href="/snoozeletter">Sign up for the Snoozeletter and get a FREE&nbsp;AUDIOBOOK</a></h1>
                <h1 className='fiction red'>Listen to the latest episode</h1>

                <div className="latest">
                    <div className="shareLinks">
                        <a className="" href={`https://twitter.com/intent/tweet?text=I'm listening to the Bedtime Stories for Insomniacs Fiction Podcast. Fresh, new, original stories to NOT fall asleep by! Check out episode ${latestStory?.title?.replace("#", "")}&url=https://BedtimeStories.studio&hashtags=FictionPodcast,ReadersCommunity,Audiobooks&via=RichHosek`}><i className="fab fa-twitter-square"></i></a>
                        <a className="btn btn-danger btn-sm" type="button" role="button" title="Share on facebook"
                            href={`https://www.facebook.com/sharer/sharer.php?u=https://BedtimeStories.studio`} target="_blank" rel="noreferer">
                            <i className="fab fa-facebook-square"></i></a>
                        <a href="https://goodpods.com/podcasts/bedtime-stories-for-insomniacs-written-by-rich-hosek-212131">
                            <img src="https://storage.googleapis.com/goodpods-images-bucket/badges/generic-badge-5.svg" alt="listen to bedtime stories for insomniacs - written by rich hosek podcast on goodpods" style={{ width: "50px" }} />
                        </a>
                    </div>
                    <a onClick={loadEpisodePlayer} href={`/episode/${latestStory?.season}/${latestStory?.episode}`}>
                        <h2>{latestStory?.title}</h2>
                        <div className="latestimg"><img src={latestStory?.imageLink} alt="" /></div>
                        <div dangerouslySetInnerHTML={{ __html: latestStory?.content }}></div>
                        {/* <a href="https://goodpods.com/podcasts/bedtime-stories-for-insomniacs-written-by-rich-hosek-212131">
  <img src="https://storage.googleapis.com/goodpods-images-bucket/badges/generic-badge-1.svg" alt="listen to bedtime stories for insomniacs - written by rich hosek podcast on goodpods" style={{width: "225px"}} />
</a> */}
                    </a>
                </div>
            </div>
        </ >
    );
}

export default Home;
