import logoApple from "./apple.png";
import logoGoogle from "./google.png";
import logoSpotify from "./spotify.png";
import logoAudible from "./audible.png";
import logoIHeartMedia from "./iheartmedia.png";
import logoAmazonMusic from "./amazonmusic.png";
import logoGoodPods from "./goodpods.png";
import logoStitcher from "./stitcher.png";
import "./index.css";

export default function() {
    return (
        <>
            <h1 className="fiction">Where to Subscribe</h1>
            <h1 className="emailList"><a style={{color: "yellow"}} href="/snoozeletter">Sign up for the Snoozeletter and get a FREE&nbsp;BOOKMARK</a></h1>
            <div className="subscription-links">
                <div className="link">
                    <a href="https://podcasts.apple.com/us/podcast/written-by-rich-hosek/id1603374090">
                        <figure><img src={logoApple} alt="Apple podcast logo" /></figure>
                        <p style={{lineHeight: "25px"}}>Apple Podcasts<span><br />(or via iTunes on Windows)</span></p>
                    </a>
                </div>
                <div className="link">
                    <a href="https://podcasts.google.com/feed/aHR0cHM6Ly93cml0dGVuYnlyaWNoaG9zZWsuY2FzdG9zLmNvbS9mZWVk">
                        <figure><img src={logoGoogle} alt="Apple podcast logo" /></figure>
                        <p style={{lineHeight: "50px"}}>Google Podcasts</p>
                    </a>
                </div>
                <div className="link">
                    <a href="https://open.spotify.com/show/0oC2s0VBuI3jpqq9LmafJm">
                        <figure><img src={logoSpotify} alt="Apple podcast logo" /></figure>
                        <p style={{lineHeight: "50px"}}>Spotify</p>
                    </a>
                </div>
                <div className="link">
                    <a href="https://www.audible.com/pd/Written-by-Rich-Hosek-Podcast/B08K57MZ6X">
                        <figure><img src={logoAudible} alt="Apple podcast logo" /></figure>
                        <p style={{lineHeight: "50px"}}>Audible</p>
                    </a>
                </div>
                <div className="link">
                    <a href="https://www.iheart.com/podcast/269-written-by-rich-hosek-91216195/">
                        <figure><img src={logoIHeartMedia} alt="Apple podcast logo" /></figure>
                        <p style={{lineHeight: "50px"}}>I 🤍 Media</p>
                    </a>
                </div>
                <div className="link">
                    <a href="https://music.amazon.com/podcasts/7c4a33f0-023d-4d19-a071-b1b46e15a40d/written-by-rich-hosek">
                        <figure><img src={logoAmazonMusic} alt="Apple podcast logo" /></figure>
                        <p style={{lineHeight: "50px"}}>Amazon Music Podcasts</p>
                    </a>
                </div>
                <div className="link" style={{textAlign: "center", width: "100%"}}>
                <a href="https://goodpods.com/podcasts/bedtime-stories-for-insomniacs-written-by-rich-hosek-212131" >
  <img src="https://storage.googleapis.com/goodpods-images-bucket/badges/generic-badge-1.svg" alt="listen to bedtime stories for insomniacs - written by rich hosek podcast on goodpods" style={{width: "225px"}} />
</a>
                </div>
                {/* <div className="link">
                    <a href="https://music.amazon.com/podcasts/7c4a33f0-023d-4d19-a071-b1b46e15a40d/written-by-rich-hosek">
                        <figure><img src={logoStitcher} alt="Apple podcast logo" /></figure>
                        <p style={{lineHeight: "50px"}}>Stitcher Podcasts</p>
                    </a>
                </div> */}
                



                    {/* <a href="https://podcasts.apple.com/us/podcast/written-by-rich-hosek/id1603374090"><img alt="" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTYgMjU2Ij48cGF0aCBkPSJNMTI4LDE0NGEyOCwyOCwwLDEsMSwyOC0yOEEyOC4xLDI4LjEsMCwwLDEsMTI4LDE0NFptMTIuNSwwaC0yNWExOS45LDE5LjksMCwwLDAtMTUuNCw3LjMsMTkuNiwxOS42LDAsMCwwLTQuMiwxNi42bDkuNiw0OEEyMC4xLDIwLjEsMCwwLDAsMTI1LjEsMjMyaDUuOGEyMC4xLDIwLjEsMCwwLDAsMTkuNi0xNi4xbDkuNi00OGExOS42LDE5LjYsMCwwLDAtNC4yLTE2LjZBMTkuOSwxOS45LDAsMCwwLDE0MC41LDE0NFptMzkuNyw0LjNhOCw4LDAsMCwwLDE0LjksNS44QTcwLjcsNzAuNywwLDAsMCwyMDAsMTI4YTcyLDcyLDAsMCwwLTE0NCwwLDcwLjMsNzAuMywwLDAsMCw0LjksMjYuMSw4LDgsMCwwLDAsNy40LDUuMSw3LjIsNy4yLDAsMCwwLDIuOS0uNiw4LDgsMCwwLDAsNC42LTEwLjMsNTYsNTYsMCwxLDEsMTA0LjQsMFpNMTI4LDI0QTEwNCwxMDQsMCwwLDAsNzIsMjE1LjdhOCw4LDAsMCwwLDguNy0xMy41LDg4LDg4LDAsMSwxLDk0LjYsMCw4LDgsMCwwLDAsNC40LDE0LjcsOC44LDguOCwwLDAsMCw0LjMtMS4yQTEwNCwxMDQsMCwwLDAsMTI4LDI0WiIvPjwvc3ZnPg==" style={{ height: "1.5rem" }} />Apple</a>
                    <a href="https://open.spotify.com/show/0oC2s0VBuI3jpqq9LmafJm">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="-33.4974 -55.829 290.3108 334.974"><path d="M177.707 98.987c-35.992-21.375-95.36-23.34-129.719-12.912-5.519 1.674-11.353-1.44-13.024-6.958-1.672-5.521 1.439-11.352 6.96-13.029 39.443-11.972 105.008-9.66 146.443 14.936 4.964 2.947 6.59 9.356 3.649 14.31-2.944 4.963-9.359 6.6-14.31 3.653m-1.178 31.658c-2.525 4.098-7.883 5.383-11.975 2.867-30.005-18.444-75.762-23.788-111.262-13.012-4.603 1.39-9.466-1.204-10.864-5.8a8.717 8.717 0 015.805-10.856c40.553-12.307 90.968-6.347 125.432 14.833 4.092 2.52 5.38 7.88 2.864 11.968m-13.663 30.404a6.954 6.954 0 01-9.569 2.316c-26.22-16.025-59.223-19.644-98.09-10.766a6.955 6.955 0 01-8.331-5.232 6.95 6.95 0 015.233-8.334c42.533-9.722 79.017-5.538 108.448 12.446a6.96 6.96 0 012.31 9.57M111.656 0C49.992 0 0 49.99 0 111.656c0 61.672 49.992 111.66 111.657 111.66 61.668 0 111.659-49.988 111.659-111.66C223.316 49.991 173.326 0 111.657 0" fill="#EEEEEE" /></svg>Spotify</a><a href="https://www.audible.com/pd/Written-by-Rich-Hosek-Podcast/B08K57MZ6X">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 0 640 512"><path d="M640 199.9v54l-320 200L0 254v-54l320 200 320-200.1zm-194.5 72l47.1-29.4c-37.2-55.8-100.7-92.6-172.7-92.6-72 0-135.5 36.7-172.6 92.4h.3c2.5-2.3 5.1-4.5 7.7-6.7 89.7-74.4 219.4-58.1 290.2 36.3zm-220.1 18.8c16.9-11.9 36.5-18.7 57.4-18.7 34.4 0 65.2 18.4 86.4 47.6l45.4-28.4c-20.9-29.9-55.6-49.5-94.8-49.5-38.9 0-73.4 19.4-94.4 49zM103.6 161.1c131.8-104.3 318.2-76.4 417.5 62.1l.7 1 48.8-30.4C517.1 112.1 424.8 58.1 319.9 58.1c-103.5 0-196.6 53.5-250.5 135.6 9.9-10.5 22.7-23.5 34.2-32.6zm467 32.7z" fill="#EEEEEE" /></svg>
                        Audible</a>
                    <a href="https://podcasts.google.com/feed/aHR0cHM6Ly93cml0dGVuYnlyaWNoaG9zZWsuY2FzdG9zLmNvbS9mZWVk"><img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTYgMjU2Ij48cGF0aCBkPSJNMTM2LDI0VjQ4YTgsOCwwLDAsMS0xNiwwVjI0YTgsOCwwLDAsMSwxNiwwWm00MCwzNmE4LDgsMCwwLDAtOCw4VjkyYTgsOCwwLDAsMCwxNiwwVjY4QTgsOCwwLDAsMCwxNzYsNjBaTTEyOCwyMDBhOCw4LDAsMCwwLTgsOHYyNGE4LDgsMCwwLDAsMTYsMFYyMDhBOCw4LDAsMCwwLDEyOCwyMDBabTAtMTI4YTgsOCwwLDAsMC04LDh2OTZhOCw4LDAsMCwwLDE2LDBWODBBOCw4LDAsMCwwLDEyOCw3MlpNODAsNjBhOCw4LDAsMCwwLTgsOHY2NGE4LDgsMCwwLDAsMTYsMFY2OEE4LDgsMCwwLDAsODAsNjBabTk2LDU2YTgsOCwwLDAsMC04LDh2NjRhOCw4LDAsMCwwLDE2LDBWMTI0QTgsOCwwLDAsMCwxNzYsMTE2Wk0zMiwxMDhhOCw4LDAsMCwwLTgsOHYyNGE4LDgsMCwwLDAsMTYsMFYxMTZBOCw4LDAsMCwwLDMyLDEwOFptNDgsNDhhOCw4LDAsMCwwLTgsOHYyNGE4LDgsMCwwLDAsMTYsMFYxNjRBOCw4LDAsMCwwLDgwLDE1NlptMTQ0LTQ4YTgsOCwwLDAsMC04LDh2MjRhOCw4LDAsMCwwLDE2LDBWMTE2QTgsOCwwLDAsMCwyMjQsMTA4WiIvPjwvc3ZnPg==" style={{ height: "1.5rem" }} />Google</a>
    
                    <a href="https://www.iheart.com/podcast/269-written-by-rich-hosek-91216195/">I <span style={{ fontSize: "1rem", display: "inline-block", position: "relative", top: "-4px" }}>🤍</span> Radio</a>
                    <a href="https://goodpods.com/podcasts/212131">🎧 Goodpods</a> */}
            </div>
        </>
    )
}