import './App.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Home from "./Pages/Home";
import Stories from './Pages/Stories';
import Novels from './Pages/Novels';
import Subscribe from './Pages/Subscribe';
import Buy from './Pages/Buy';
import Submissions from './Pages/Submissions';
import List from './Pages/List';
import Bookmark from './Pages/Bookmark';
import { FeedProvider } from "./FeedContext";
import axios from "axios";
// import Episode from './Pages/Episode';

function App() {

    let [feed, setFeed] = useState({ items: [] });
    useEffect(() => {
        axios.get("/api/feed")
            .then(response => {
                console.log("FEED", response.data)
                setFeed(response.data)
            })
    }, [])

    return (
        <FeedProvider value={feed}>
            <Router>
                <div className="App">
                    <Link to="/">
                        <h1 className="bedtime">Bedtime Stories</h1>
                        <h1 className="insomniac">For Insomniacs</h1>
                    </Link>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="/stories/:genre?">
                        <Stories />
                    </Route>
                    <Route path="/novels">
                        <Novels />
                    </Route>
                    <Route path="/subscribe">
                        <Subscribe />
                    </Route>
                    <Route path="/buy">
                        <Buy />
                    </Route>
                    <Route path="/submissions">
                        <Submissions />
                    </Route>
                    <Route path="/list/:emailAddress?" exact>
                        <List />
                    </Route>
                    <Route path="/snoozeletter/:emailAddress?" exact>
                        <List />
                    </Route>
                    <Route path="/list/manage/:encodedId">
                        <List />
                    </Route>
                    <Route path="/snoozeletter/manage/:encodedId">
                        <List />
                    </Route>
                    <Route path="/bookmark/:encodedId">
                        <Bookmark />
                    </Route>
                    {/* <Route path="/episode/:season/:episode/:auto?">
                        <Episode />
                    </Route> */}
                    <div className="navContainer">
                        <div className='nav'>
                            <Link to="/stories"><button className='stories'>Stories</button></Link>
                            <Link to="/novels"><button className='novels'>Novels</button></Link>
                            <Link to="/subscribe"><button className='listen'><span className="hide">Where to </span>Subscribe</button></Link>
                            <Link to="/buy"><button className='buy'>Buy<span className="hide"> the Books</span></button></Link>
                            <Link to="/submissions"><button className='submissions'>Submissions</button></Link>
                            {/* <a href="https://twitter.com/RichHosek/status/1596167811474550785?s=20&t=32Xfn4_OnvVyxVASleAkOA"><button className="highlight">VOTE</button></a> */}
                        </div>
                    </div>
                </div>
            </Router>
        </FeedProvider>
    );
}

export default App;
